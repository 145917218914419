import {gql, useQuery} from "@apollo/client";
import {FindStudentByID, FindStudent} from "./basicQueries";


const StudentGraduationRequirementFulfillmentFragment = gql`
  fragment StudentGraduationRequirementFulfillmentFragment on StudentGraduationRequirementFulfillment {
    id
    isComplete
    courseName
    credit
    mark
    sectionEnrollmentId
    sectionId
    term
    schoolYear
    testedOut
    transferredIn
    fromGradTool  
    status
    schoolName
    online  
    listedAs
  }
`

const StudentGraduationRequirementFragment = gql`
  ${StudentGraduationRequirementFulfillmentFragment}

  fragment StudentGraduationRequirementFragment on StudentGraduationRequirement {
    id
    creditsCompleted
    creditsInProgress
    creditsRequired
    name
    requirementGroup
    fulfillments {
      ...StudentGraduationRequirementFulfillmentFragment
    }
  }
`

const StudentGraduationRequirementsQuery = gql`
  ${StudentGraduationRequirementFragment}

  query StudentGraduationRequirements($studentId: ID!) {
		studentGraduationRequirements(studentId: $studentId) {
      ...StudentGraduationRequirementFragment
    }
  }
`

const FindPlan = (id) => {

  let studentId;
  if (id) {
    studentId = FindStudentByID(id);

  } else {
    studentId = FindStudent();
  }

  const gradReq = useQuery(StudentGraduationRequirementsQuery,
    {
      skip: !studentId,
      variables: { studentId },
      fetchPolicy: 'cache-and-network'
    }
  );

  return gradReq;
}


export default FindPlan;





