import { Col, Row} from "reactstrap";
import PlanOverview from "../dashboard/PlanOverview";
import DashCard from "../../../components/dashboard/dashboardCards/DashCard";

const StudentPlanContainer = () => {

    return (
      <DashCard
        title="Student Plan"
      >
        <div>
          <Row>
              <Col lg="12" sm="12">
                <PlanOverview/>
              </Col>
          </Row>
        </div>
      </DashCard>
    );

};

export default StudentPlanContainer;
