import { lazy } from 'react';

import * as Icon from "react-feather";
import Loadable from '../layouts/loader/Loadable';
import StudentCalendarContainer from "../views/school/student/studentSchoolCalender";
import StudentGradesContainer from "../views/school/student/studentGrades";
import StudentAttendanceContainer from "../views/school/student/studentAttendanceDetails";
import ParentRegistration from "../views/school/dashboard/RegistrationApp";
import StudentPlanContainer from "../views/school/student/studentPlan";

/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const StudentSchoolDashboard = Loadable(lazy(() => import('../views/school/dashboard/DashboardApp')));
const ParentSchoolDashboard = Loadable(lazy(() => import('../views/school/dashboard/ParentDashboardApp')));
const SchoolSchedule = Loadable(lazy(() => import('../views/school/schedule/scheduleApp')));
const ProfileDetails = Loadable(lazy(() => import('../views/school/profile/myProfile')));
const CoursesDetails = Loadable(lazy(() => import('../views/school/coursesApp/CalendarApp')));
const StudentDashboard = Loadable(lazy(() => import('../views/school/student/StudentDashboardApp')));
const NewsAndAnnouncements = Loadable(lazy(() => import('../views/school/announcements/AnnouncementsApp')));
const Discipline = Loadable(lazy(() => import('../views/school/discipline/DisciplineApp')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));


const StudentSidebarData = [
  { caption: 'Home' },
  {
    title: 'My Dashboard',
    href: '/school/dashboard',
    icon: <Icon.Home />,
    id: 1.6,
    collapisble: false,
    topLevel: true
  },
  {
    title: 'My Calendar',
    href: '/school/calendar',
    icon: <Icon.Calendar />,
    id: 1.7,
    collapisble: false,
    topLevel: true
  },
  {
    title: 'My Schedule',
    href: '/school/schedule',
    icon: <Icon.BellOff />,
    id: 1.8,
    collapisble: false,
    topLevel: true
  },

  // {
  //   title: 'Discipline',
  //   href: '/school/discipline',
  //   icon: <Icon.FileText />,
  //   id: 1.10,
  //   collapisble: false,
  //   topLevel: true
  // },
  {
    title: 'News & Announcements',
    href: '/school/news&announcements',
    icon: <Icon.Radio />,
    id: 1.9,
    collapisble: false,
    topLevel: true
  },
  {
    title: 'My Plan',
    href: '/school/plan',
    icon: <Icon.Compass />,
    id: 1.10,
    collapisble: false,
    topLevel: true
  }
];

// const ParentSidebarData = [
//   { caption: 'Home' },
//   {
//     title: 'My Dashboard',
//     href: '/school/dashboard',
//     icon: <Icon.Home />,
//     id: 1.6,
//     collapisble: false,
//   },
//   { caption: 'Kids' },
// ];


/*****Routes******/
export const GEENotLoggedInRoutes = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/', element: <LoginFormik /> },
      { path: '*', element: <Error /> }
    ]
  }
];


export const GEEStudentRoutes = [
  {
    path: '/',
    element: <FullLayout {...StudentSidebarData}/>,
    children: [
      {path: '/', name: 'myDashboard', exact: true, element: <StudentSchoolDashboard/>},
      {path: '/school/dashboard', name: 'myDashboard', exact: true, element: <StudentSchoolDashboard/>},
      {path: '/school/calendar', name: 'myCalendar', exact: true, element: <StudentCalendarContainer/>},
      {path: '/school/schedule', name: 'mySchedule', exact: true, element: <SchoolSchedule/>},
      {path: '/school/myProfile', name: 'myProfile', exact: true, element: <ProfileDetails/>},
      {path: '/school/courses/:sectionEnrollmentId', name: 'courseDetails', exact: true, element: <CoursesDetails/>},
      {path: '/school/news&announcements', name: 'News&Announcements', exact: true, element: <NewsAndAnnouncements/>},
      {path: '/school/discipline', name: 'Discipline', exact: true, element: <Discipline/>},
      {path: '/school/plan', name: 'Plan', exact: true, element: <StudentPlanContainer/>},
      {path: '*', element: <Error />}
    ]
  }

];

export function getParentRoutes(students) {

  const kidsRoutes = [];
  kidsRoutes.push(({path: '/', name: 'myDashboard', exact: true, element: <ParentSchoolDashboard/>}));
  kidsRoutes.push(({path: '/school/dashboard', name: 'myDashboard', exact: true, element: <ParentSchoolDashboard/>}));
  kidsRoutes.push(({path: '/school/registration', name: 'myDashboard', exact: true, element: <ParentRegistration/>}));
  kidsRoutes.push(({path: '/school/myProfile', name: 'myProfile', exact: true, element: <ProfileDetails/>}));
  kidsRoutes.push(({path: '/school/:schoolId/student/:schoolEnrollmentId', name: 'studentDetails', exact: true, element: <StudentDashboard/>}));
  kidsRoutes.push(({path: '/school/:schoolId/student/:studentPersonId/courses/:sectionEnrollmentId', name: 'studentDetails', exact: true, element: <CoursesDetails/>}));
  kidsRoutes.push(({path: '/school/:schoolId/student/calendar/:studentPersonId', name: 'studentDetails', exact: true, element: <StudentCalendarContainer/>}));
  kidsRoutes.push(({path: '/school/:schoolId/student/grades/:studentPersonId', name: 'studentDetails', exact: true, element: <StudentGradesContainer/>}));
  kidsRoutes.push(({path: '/school/:schoolId/student/attendance/:schoolEnrollmentId', name: 'studentDetails', exact: true, element: <StudentAttendanceContainer/>}));
  kidsRoutes.push(({path: '/school/:schoolId/news&announcements', name: 'News&Announcements', exact: true, element: <NewsAndAnnouncements/>}));
  kidsRoutes.push(({path: '/school/:schoolId/student/discipline/:schoolEnrollmentId', name: 'discipline', exact: true, element: <Discipline/>}));
  kidsRoutes.push(({path: '*', element: <Error />}));



  const sideBarData = [
    { caption: 'Home' },
    {
      title: 'My Dashboard',
      href: '/school/dashboard',
      icon: <Icon.Home />,
      id: 1.6,
      collapisble: false,
      topLevel: true
    },
    { caption: 'Kids' },
  ];
  for (let i = 0; i < students?.length; i++) {
    sideBarData.push(
      ({
        title: `${students[i].firstName}`,
        icon: <Icon.User />,
        id: `${i}`,
        collapisble: true,
        children: [
          {
            title: `Dashboard`,
            href: `/school/${students[i].schoolId}/student/${students[i].schoolEnrollmentId}`,
            icon: <Icon.Home />,
            id: `${i}.1`,
            collapisble: true,
            topLevel: true
          },
          {
            title: 'Grades',
            href: `/school/${students[i].schoolId}/student/grades/${students[i].id}`,
            icon: <Icon.Calendar />,
            id: `${i}.2`,
            collapisble: false,
            topLevel: true
          },
          {
            title: 'School Calendar',
            href: `/school/${students[i].schoolId}/student/calendar/${students[i].id}`,
            icon: <Icon.Calendar />,
            id: `${i}.3`,
            collapisble: false,
            topLevel: true
          },
          {
            title: 'Attendance',
            href: `/school/${students[i].schoolId}/student/attendance/${students[i].schoolEnrollmentId}`,
            icon: <Icon.Table />,
            id: `${i}.4`,
            collapisble: false,
            topLevel: true
          },
          {
            title: 'Discipline',
            href: `/school/${students[i].schoolId}/student/discipline/${students[i].id}`,
            icon: <Icon.Radio />,
            id: `${i}.5`,
            collapisble: false,
            topLevel: true
          },
          {
            title: 'News',
            href: `/school/${students[i].schoolId}/news&announcements`,
            icon: <Icon.Radio />,
            id: `${i}.6`,
            collapisble: false,
            topLevel: true
          }
        ]
      })
    )
  }

  const GEEParentRoutes = [
    {
      path: '/',
      element: <FullLayout {...sideBarData}/>,
      children: kidsRoutes
    }

  ];
  return GEEParentRoutes

}
