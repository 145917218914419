import React, { useState } from 'react';
import {useParams} from "react-router-dom";
import {Spinner, Table, Collapse} from "reactstrap";
import FindPlan from "../../../graphql/planQueries";
import {FindEnrollment} from "../../../graphql/basicQueries";
import {isElementary} from "../../../helpers/viewHelper";

const PlanOverview = () => {
  const { studentPersonId } = useParams();
  const plan = FindPlan(studentPersonId);
  const enrollment = FindEnrollment();
  let elmentary = true;
  if (enrollment) {
    const gradeLevel = enrollment?.gradeLevel;
    elmentary = isElementary(gradeLevel)
  }

  let planDetails = [];
  if (plan?.data) {
    planDetails = plan.data?.studentGraduationRequirements
    planDetails = [...planDetails]?.sort((a, b) => {
      if (a.requirementGroup < b.requirementGroup) {
        return -1;
      }
      if (a.requirementGroup > b.requirementGroup) {
        return 1;
      }
      return 0;
    });
  }

  const groupedPlanDetails = planDetails.reduce((grouped, item) => {
    const { requirementGroup, name, creditsCompleted, fulfillments, creditsRequired } = item;
    const key = requirementGroup;

    if (!grouped[key]) {
      grouped[key] = {
        groupName: requirementGroup,
        totalCreditsRequired: 0,
        totalCreditsCompleted: 0,
        items: []
      };
    }

    grouped[key].totalCreditsRequired += creditsRequired;
    grouped[key].totalCreditsCompleted += creditsCompleted;
    grouped[key].items.push({
      name,
      totalCompleted: creditsCompleted,
      totalRequired: creditsRequired,
      fulfillments
    });

    return grouped;
  }, {});

  // Convert the grouped object to an array
  const groupedPlanDetailsArray = Object.values(groupedPlanDetails);

  // Create a state variable for each group to track whether its subtable is open
  const [isOpen, setIsOpen] = useState(groupedPlanDetailsArray.reduce((acc, item) => ({ ...acc, [item.groupName]: false }), {}));

  const toggle = groupName => {
    setIsOpen({ ...isOpen, [groupName]: !isOpen[groupName] });
  };

  if (elmentary) {
    return (
      <div>
        <h1>Elementary Plan</h1>
        <p>Elementary students do not have a graduation plan</p>
      </div>
    );
  }
  return (
    <div>
      {planDetails.length > 0 ? (
        <div className="table-responsive">
          <Table className="text-truncate mt-3 align-middle border" responsive>
            <thead>
            <tr>
              <th>Requirement Group</th>
              <th>Required</th>
              <th>Completed</th>
            </tr>
            </thead>
            <tbody>
            {groupedPlanDetailsArray.map((item) => (
              <React.Fragment key={item.groupName}>
                <tr onClick={() => toggle(item.groupName)}>
                  <td>{isOpen[item.groupName] ? '▼' : '►'} {item.groupName}</td>
                  <td>{item.totalCreditsRequired}</td>
                  <td>{item.totalCreditsCompleted}</td>
                </tr>
                <Collapse isOpen={isOpen[item.groupName]}>
                  <Table style={{ marginLeft: '50px' }}>
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Required</th>
                      <th>Completed</th>
                    </tr>
                    </thead>
                    <tbody>
                    {item.items.map((subItem) => (
                      <React.Fragment key={subItem.name}>
                        <tr>
                          <td>{subItem.name}</td>
                          <td>{subItem.totalRequired}</td>
                          <td>{subItem.totalCompleted}</td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <Table style={{ marginLeft: '50px' }}>
                              <thead>
                              <tr>
                                <th>Completed Courses</th>
                                <th>Grade</th>
                                <th>Credit</th>
                                <th>School Year</th>
                              </tr>
                              </thead>
                              <tbody>
                              {subItem.fulfillments.filter(fulfillment => fulfillment.isComplete).length > 0 ? (
                                subItem.fulfillments).map((fulfillment) => (
                                  <tr>
                                    <td> {fulfillment.courseName}</td>
                                    {/*{fulfillment.isComplete ?*/}
                                    {/*  (<td> <span className="p-2 d-inline-block ms-3">&#10003; </span>{fulfillment.courseName}</td>)*/}
                                    {/*  :(<td> <span className="p-2 d-inline-block ms-3">X </span>{fulfillment.courseName}</td>)}*/}
                                    <td> {fulfillment.mark}</td>
                                    <td> {fulfillment.credit}</td>
                                    <td> {fulfillment.schoolYear}</td>
                                  </tr>
                                )
                              ) : (
                                <tr>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                              )}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                    </tbody>
                  </Table>
                </Collapse>
              </React.Fragment>
            ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <Spinner color="primary" />
      )}
    </div>
  );

};

export default PlanOverview;
